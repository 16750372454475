export default {
    "translation": {
        "hi": "Здравей,",
        "ok": "ОК",
        "back": "Назад",
        "name": "Име",
        "family": "Фамилно име",
        "email": "Електронна поща",
        "password": "Парола",
        "password-requirements": "* Изисквания за парола: дължина от 6 символа, трябва да включва поне: 1 главна буква и 1 малка буква, 1 цифра ",
        "phone": "Телефонен номер",
        "confirm-text": "На предоставения мейл адрес беше изпратен код за потвърждение. Въведете го в полето по-долу, за да завършите регистрацията си. Кодът е валиден 15 минути.",
        "birth-date": "Дата на раждане",
        "register": "Попълни формуляр",
        "register-button": "РЕГИСТРАЦИЯ",
        "birth-date-is-required": "Датата на раждане е задължителна!",
        "terms": "Общите условия за ползване на",
        "tickets-sells-start": "Билетите ще бъдат в продажба от 10:00 на 26.10.2023!",
        "faq": "Често задавани въпроси",
        "faq-link": "https://d3u845fx6txnqz.cloudfront.net/ts/ED+Sheeran+FAQs+BG.pdf",
        "terms-link-1": "https://d3u845fx6txnqz.cloudfront.net/ts/terms-and-conditions-TS-BG.pdf",
        "terms-link-2": "https://d3u845fx6txnqz.cloudfront.net/ts/General+terms+fest+team+16.10.2023.pdf",
        "privacy-link": "https://d3u845fx6txnqz.cloudfront.net/ts/privacy_policy_TicketStation_BG.pdf",
        "artist-terms":"Общи условия на събитието",
        "chose-date": "Изберете дата",
        "artist-terms-link": "https://d3u845fx6txnqz.cloudfront.net/ts/Event+Terms+and+Conditions+BG.pdf",
        "consent": "Съгласен/на съм със събирането и споделянето на",
        "consent-2": "Прочетох и се съгласявам със събирането и споделянето на моите данни и ",
        "my-data": "моите данни и",
        "privacy": "Политиката за поверителност",
        "accept-terms": "Моля отбележете, че сте съгласните с условията за ползване и политиката за поверителност",
        "save-btn": "Запиши",
        "add-btn": "ДОБАВИ",
        "close": "Затвори",
        "remove-friend": "Сигурни ли сте, че искате да прамахнете този приятел?",
        "why": "Защо да попълня формуляра?",
        "confirm": "Потвърди",
        "confirmation-code": "Код за потвърждение",
        "why-modal-text-1": "ВАЖНО: Попълването на настоящия формуляр НЕ Ви обвързва с покупка! Потребители, които не са попълнили формуляра, ще ИМАТ равна възможност да закупят билети след началото на официалната им продажба.",
        "why-modal-text-2": "Моля, имайте предвид, че формулярът има изцяло информативна и помощна цел.",
        "why-modal-text-3": "Той НЕ гарантира наличност на билети. Попълването му НЕ представлява резервация на билети!",
        "why-modal-text-4": "Ние от Fest Team, заедно с екипа на Ticket Station и за пръв път в България - с AEG Presents, знаем колко огромен е интересът към концерта на Ed Sheeran у нас. По тази причина ви каним да добавите предварително необходимите от Вас данни за покупка на билет за събитието.",
        "why-modal-text-5": "ЗАЩО ДА ПОПЪЛНИТЕ ФОРМУЛЯРА?",
        "why-modal-text-6": "По-кратък процес за покупка на билети след старта на официалната продажба.",
        "why-modal-text-7": "Ще получите лична информация предварително какви са условията за покупка на билети.",
        "why-modal-text-8": "Ще получавате лично всяка информация, изисквания и правила за посещение на концерта.",
        "why-modal-text-9": "Формулярът ще е активен между 11:00 на 21 октомври и 23:59ч. на 25 октомври 2023.",
        "why-modal-text-10": "При попълване, ще можете да въведете своите и данните на още до петима души, с които желаете да посетите събитието. Данните могат да бъдат редактирани впоследствие, както и директно при покупка.",
        "why-modal-text-11": "При започването на официалните продажби в 10:00 на 26 октомври, ще можете директно да влезете в опашката за покупка на билети и Вашите данни вече ще бъдат налични, без да губите време.",
        "why-modal-text-12": "ВАЖНО:",
        "why-modal-text-13": "Формулярът НЕ гарантира наличност на билети. Наличността е ограничена спрямо капацитета на мястото на провеждане на събитието и вече продадения към момента на достъпване брой билети.",
        "why-modal-text-14": "Попълването на формуляра е ПРЕПОРЪЧИТЕЛНО, но не задължително. Потребители, които не са попълнили формуляра, ЩЕ ИМАТ равна възможност да закупят билети след началото на официалната им продажба и след въвеждане на изискуемите данни. Всички потребители ще бъдат насочени към автоматизирана опашка за регулиране на трафика, където ще могат да проследят кои поред са, както и след какво ориентировъчно време ще се зареди страницата за покупка на билети.",
        "why-modal-text-15": "Формулярът може да бъде попълнен както от съществуващи, така и от нови клиенти на Ticket Station.",
        "do-the-math": 'Do The Math',


        "Incorrect username or password.": "Невалидно потребителско име или парола.",
        "User does not exist.": "Потребителят не съществува.",
        "An account with the given email already exists.": "Вече съществува акаунт с този имейл.",
        "Password did not conform with policy: Password not long enough": "Паролата не отговаря на изискванията: Паролата не е достатъчно дълга",
        "Password did not conform with policy: Password must have lowercase characters": "Паролата не отговаря на изискванията: Паролата трябва да съдържа малки букви",
        "Password did not conform with policy: Password must have uppercase characters": "Паролата не отговаря на изискванията: Паролата трябва да съдържа главни букви",
        "Invalid phone number format.": "Невалиден формат на телефонния номер.",
        "Password did not conform with policy: Password must have numeric characters" : "Паролата не отговаря на изискванията: Паролата трябва да съдържа цифри",

        "repeat-password": "Потвърдете паролата",
        "password-match": "Паролите не съвпадат!",
        "invalid-email": "Невалиден Е-майл.",
        "invalid-phone": "Невалиден телефон!",

        "reg-hed-part-1": "Регистрацията на тази страница автоматично създава",
        "reg-hed-part-2": "ваш профил",
        "reg-in": "в",
        "reg-and": "и",
        "reg-fest": "festclub.bg",
        "fill-field": "Моля, попъленте полето.",
        "reg-pre": "БЕЛЕЖКА! Попълването на формуляра ще включи вашите данни в акаунта ви в ticketstation.bg. TicketStation е официалния разпространител на билети за концерта в България. По този начин, след като стартират официалните продажби – ще трябва само да влезете в акаунта си, за да се появят въведените данни автоматично.",

        "home-heading-first": "Ed Sheeran: +-=÷x Tour",
        "home-already": "Вече имаш регистрация в Ticketstation?",
        "date": "Дата",
        "event-date": '31 август 2024',
        "place": "Място",
        "event-place": "Национален стадион \"Васил Левски\", София, България",
        "home-login": "Влез в акаунта си.",
        "home-heading-second": "AEG Presents & Fest Team by arrangement with One Fiinix Live",
        "home-heading-date": "Запиши данните си предварително в „Do the math“ формуляра и бъди готов за официалните продажби",
        "home-pre-sale-1": "За да направим процеса на закупуване на билети по-лесен и бърз, Ви каним да попълните настоящия формуляр преди началото на официалните продажби. По този начин, данните Ви ще бъдат автоматично заредени без забавяне, когато билетите влязат в продажба.",
        "home-pre-sale-2": "Формулярът не е задължителен за закупуване на билет и ще бъде активен в периода от 12:00 ч. на 20 октомври до 23:59ч. на 25 октомври 2023.",
        "home-pre-sale-3": "Билетите ще бъдат в продажба: 10:00 часа, 26 октомври 2023",
        "submit-heading": "Попълвайки формуляра, ще:",
        "submit-text-1": "Получите лично информация за условията за купуване на билет и посещение на концерта.",
        "submit-text-2": "Ускорите и улесните процеса на закупуване на билети след пускането им в продажба. ",

        "thank-you-sale": "Предварителната продажба на билети ще започне в",
        "thank-you-date": "10:00ч., 26 октомври 2023 г.",
        "thank-you-btn": "РЕГИСТРИРАЙ МЕ",
        "thank-you-reg-message": "Регистрирай се за предварителната продажба на билети за невероятния концерт на Derullica и стани един от първите фенове с достъп до концерта на годината!",

        "login-acc": "Влез със своя Ticketstation акаунт",
        "log": "Вход",

        "thank-you-for-reg": "Благодарим Ви, че попълнихте своите данни предварително!",
        "already-part": "Вие се регистрирахте със своя акаунт в TicketStation.bg, който е официалният разпространител на билети за концерта на Ed Sheeran в България.",
        "concert": "невероятния концерт на Derullica!",
        "pre-sale-starts": "Билетите ще бъдат официално пуснати в продажба на:",
        "extra": "Планирате ли да закупите допълнителни билети за вашето семейство и приятели?",
        "btn-down": "Добавете ги сега в своя акаунт, така че данните да бъдат въведени при закупуването на билети:",
        "max-tickets": "Искам да добавя семейство и приятели (разрешени са до 5 души за този концерт)",
        "max-tickets-reached": "Достигнат е максималният брой допълнителни билети",
        "extra-tickets": "Добавени семейство и приятели:",
        "friends-attention": "Уверете се, че имате техни актуални телефон и имейл, за да могат да получават най-важната информация и условията за посещение на концерта!",
        "attention": "Важно:",
        "phone-attention": "За всеки допълнителен билет, който планирате да закупите – ще трябва да влезете на концерта заедно спрямо правилата на турнето.",
        "saved": "Промените са запазени.",
        "follow-us": "Последвай ни",
        "ticket-for": "Попълнете детайлите на притежателя на билета – семейство и приятели:",
        "logout": 'Изход',
        "description-1": "Преди пускането на турнето в продажба получаваме много въпроси, свързани с концертите и покупката на билети.",
        "description-2": "За да бъдем сигурни, че билетите ще попаднат само в ръцете на истински фенове, продажбата на билети и достъпа концертите налагат специални условия, които всеки посетител следва да спази.",
        "description-3": "Особено важното е да бъдете добре информирани – вие и всеки, с когото планирате да споделите преживяването.",
        "description-4": "За това уверете се, че проверявате редовно мейла, който вписахте.",
        "error": "Грешка",
        "invalid-code": 'Невалиден код',

        "something-wrong-1": "Уважаеми фенове,",
        "something-wrong-2": "Нашата уеб страница в момента е изложена на изключително висок трафик, което e и причина за временни технически затруднения.",
        "something-wrong-3": "Нашият екип работи усилено, за да разреши възникналите предизвикателства, и да ви осигури достъп до страницата.",
        "something-wrong-4": "Моля, бъдете търпеливи, очакваме, че сайта ще функционира гладко много скоро. Междувременно, можете да проверите",
        "something-wrong-5": "Общите условия на турнето.",
        "something-wrong-6": "Искрено ценим вашето търпение.",
        "something-wrong-7": "Благодарим ви, че сте част от тази невероятна общност от фенове.",
        "something-wrong-8": "С най-добри пожелания,",
        "something-wrong-9": "Ticket Station",

        "email-format-error" : "Невалиден E-Mail формат!",
        "email-owner-error" : "Собственика използва този E-Mail!",
        "email-friend-error" : "Вече имате приятел с този E-Mail!",
        "to-be": "София! Ed Sheeran идва за концерт от своето Mathematics турне за пръв път в България на Стадион \"Васил Левски\" на 31 август! Taзи страница ще бъде активна в 12:00 днес и ще Ви предостави възможност да се подготвите за официалните продажби на билети за събитието.",
        "closed-text-1": "Регистрацията приключи!",
        "closed-text-2": "Билетите влизат в продажба в 10:00 на 26.10.2023",
        "buy-tickets": "Купи билети",
    }
}