import { Config, CognitoIdentityCredentials } from "aws-sdk";
import {
  CognitoUser,
  CognitoUserPool,
  AuthenticationDetails,
  CognitoUserAttribute
} from "amazon-cognito-identity-js";

class CognitoAuth {
    static options = new CognitoUserPool(require('@/config').default);

    constructor() {
        this.userSession = null;
    }

    getCurrentUser () {
        return CognitoAuth.options.getCurrentUser()
    }

    isAuthenticated (cb) {
        let cognitoUser = this.getCurrentUser()
        if (cognitoUser != null) {
            cognitoUser.getSession((err) => {
            if (err) {
                return cb(err, false)
            }
            return cb(null, true)
            })
        } else {
            cb(null, false)
        }
    }
  
    authenticate = (username, pass, cb) => {
        let authenticationData = { Username: username, Password: pass }
        let authenticationDetails = new AuthenticationDetails(authenticationData)
        let userData = { Username: username, Pool: CognitoAuth.options }
        let cognitoUser = new CognitoUser(userData)

        cognitoUser.authenticateUser(authenticationDetails, {
            onSuccess: function (result) {
                // console.log('access token + ' + result.getAccessToken().getJwtToken())
                var logins = {}
                logins['cognito-idp.' + CognitoAuth.options.region + '.amazonaws.com/' + CognitoAuth.options.userPoolId] = result.getIdToken().getJwtToken()
                // console.log(logins)


                Config.credentials = new CognitoIdentityCredentials({
                    IdentityPoolId: CognitoAuth.options.UserPoolId,
                    Logins: logins
                })
                // console.log(Config.credentials)
                // this.onChange(true)
                cb(result)
            },
            onFailure: function (err) {
                cb(err);
            },
            // newPasswordRequired: function (userAttributes, requiredAttributes) {
            newPasswordRequired: function () {
                // console.log('New Password Is Required')
            }
        })
    }

    signup(data, cb) {
        let attributeList = [
            new CognitoUserAttribute({
                Name: 'email',
                Value: data.email
            }),
            new CognitoUserAttribute({
                Name: 'phone_number',
                Value: data.phone
            }),
            new CognitoUserAttribute({
                Name: 'name',
                Value: data.name
            }),
            new CognitoUserAttribute({
                Name: 'family_name',
                Value: data.family_name
            }),
            new CognitoUserAttribute({
                Name: 'phone_number',
                Value: data.phone
            })
        ]
    
        CognitoAuth.options.signUp(data.email, data.password, attributeList, null, cb)
    }

    confirmSignUp(data,cb) {
        let userData = { Username: data.username, Pool: CognitoAuth.options }
        let cognitoUser = new CognitoUser(userData)
        cognitoUser.confirmRegistration(data.code, true, function(err, result) {
            if (err) {
                cb({message: 'error'});
                // alert(err.message || JSON.stringify(err));
                return;
            }
            
            cb(result);
        });
      }

    logout () {
        CognitoAuth.options.getCurrentUser().signOut()
    }
}

export default CognitoAuth;