export default {
  "translation": {
      "hi": "Hi,",
      "ok": "ОК",
      "back": "Back",
      "name": "Name",
      "family": "Family name",
      "email": "E-mail",
      "password": "Password",
      "password-requirements": "* Password requirements: 6 characters long, must include at least: 1 uppercase letter and 1 lowercase letter, 1 number",
      "phone": "Phone number",
      "confirm-text": "We’ve sent a verification code to your e-mail. Please, enter it below to complete your registration. The code will expire in 15 minutes.",
      "birth-date": "Birth date",
      "register": "Fill Now",
      "register-button": "REGISTER",
      "birth-date-is-required": "Birth date is mandatory!",
      "tickets-sells-start": "Tickets will be on sale from 10:00 on 10/26/2023!",
      "faq": "FAQs",
      "chose-date": "Choose date",
      "faq-link": "https://d3u845fx6txnqz.cloudfront.net/ts/ED+Sheeran+FAQs+EN.pdf",
      "terms-link-1": "https://d3u845fx6txnqz.cloudfront.net/ts/terms-and-conditions-TS-EN.pdf",
      "terms-link-2": "https://d3u845fx6txnqz.cloudfront.net/ts/General+Terms+and+Conditions+Fest+Team+16.10.2023.pdf",
      "privacy-link": "https://d3u845fx6txnqz.cloudfront.net/ts/privacy_policy_TicketStation_EN.pdf",
      "artist-terms":"Event Terms and Conditions",
      "artist-terms-link": "https://d3u845fx6txnqz.cloudfront.net/ts/Event+Terms+and+Conditions+EN.pdf",
      "terms": "Terms and Conditions of Use of",
      "consent": "I have read and agree to the",
      "consent-2": "I have read and agree to the Privacy Policy - ",
      "my-data": "моите данни и",
      "privacy": "I agree to the collection and sharing of my data and the Privacy Policy",
      "accept-terms": "Please check that you agree to the terms of use and the privacy policy",
      "save-btn": "Apply",
      "add-btn": "ADD",
      "close": "Close",
      "remove-friend": "Are you sure you want to remove this friend?",
      "why": "Why to Fill the Form",
      "confirm": "Confirm",
      "confirmation-code": "Confirmation code",
      "why-modal-text-1": "IMPORTANT: This form does not obligate you to make a purchase!",
      "why-modal-text-2": "Users who have not filled this form WILL HAVE an equal opportunity to purchase tickets after the official release.",
      "why-modal-text-3": "Please be aware that this form serves purely as an informative and supportive purposes. It does NOT guarantee ticket availability. The form does NOT represent a ticket reservation.",
      "why-modal-text-4": "Fest Team, in collaboration with Ticket Station, and for the first time in Bulgaria, alongside AEG Presents, understands the immense interest in Ed Sheeran's concert in our country. This is why we invite you to save your data prior the official ticket sales the event start.",
      "why-modal-text-5": "WHY FILL THE FORM IN ADVANCE?",
      "why-modal-text-6": "A shorter ticket purchasing process when sales officially begin.",
      "why-modal-text-7": "You'll receive personal information about ticket purchasing conditions.",
      "why-modal-text-8": "You'll receive personally all the details, requirements, and rules for attending the concert.",
      "why-modal-text-9": "The form will be active from 11:00 on October 21 to 23:59 on October 25, 2023.",
      "why-modal-text-10": "At the form, you can enter your information and the details of up to five people you'd like to attend the event with. This information can be edited later or directly during the ticket purchase.",
      "why-modal-text-11": "When the official sales start at 10:00 AM on October 26, you can join the ticket purchase queue directly, and your details will already be available, saving you time.",
      "why-modal-text-12": "IMPORTANT:",
      "why-modal-text-13": "The form DOES NOT guarantee ticket availability. Availability is limited to the venue's capacity and the number of tickets already sold at the time of access.",
      "why-modal-text-14": "Filling the form is RECOMMENDED but not mandatory. Users without registration will have equal opportunities to purchase tickets once official sales begin and after providing the required information. All users will be directed to an automated queue to manage traffic, where they can track their place in line and get an estimate of when the ticket purchase page will load.",
      "why-modal-text-15": "This form is open to both existing and new Ticket Station customers.",
      "do-the-math": 'Do The Math',


      "Incorrect username or password.": "Incorrect username or password!",
      "User does not exist.": "User does not exist!",
      "An account with the given email already exists.": "An account with the given email already exists!",
      "Password did not conform with policy: Password not long enough": "Password did not conform with policy: Password not long enough!",
      "Password did not conform with policy: Password must have lowercase characters": "Password did not conform with policy: Password must have lowercase characters!",
      "Password did not conform with policy: Password must have uppercase characters": "Password did not conform with policy: Password must have uppercase characters",
      "Invalid phone number format.": "Invalid phone number format!",
      "Password did not conform with policy: Password must have numeric characters" : "Password did not conform with policy: Password must have numeric characters",

      "repeat-password": "Confirm password",
      "password-match": "Passwords are not the same!",
      "invalid-email": "Invalid E-Mail!",
      "invalid-phone": "Invalid phone number!",

      "reg-hed-part-1": "Регистрацията на тази страница автоматично създава",
      "reg-hed-part-2": "ваш профил",
      "reg-in": "в",
      "reg-and": "и",
      "reg-fest": "festclub.bg",
      "fill-field": "Please fill the field!",
      "reg-pre": "NOTE! Filling the form would include your details in your account in ticketstation.bg. TicketStation.bg is the official ticket seller for the concert of Ed Sheeran in Bulgaria. Once the ticket sales are open – you will just need to enter your account so your details would appear automatically.",

      "home-heading-first": "Ed Sheeran: +-=÷x Tour",
      "home-already": "Already a member of Ticketstation.bg?",
      "date": "Date",
      "event-date": 'August 31, 2024',
      "place": "Venue",
      "event-place": "Vasil Levski National Stadium, Sofia, Bulgaria",
      "home-login": "Log into your account.",
      "home-heading-second": "AEG Presents & Fest Team by arrangement with One Fiinix Live",
      "home-heading-date": "Add your details and prepare for ticket launch using the customer detail form.",
      "home-pre-sale-1": "To make ticketing purchase process easier and effortless, we invite you to fill the details form prior to official sales beginning. This will help you to purchase your tickets faster with no delays or extra time waiting!",
      "home-pre-sale-2": "Filling the form is not mandatory to grab a ticket and will be active between 12:00 PM on October 20 until 11:59 PM on October 25, 2023",
      "home-pre-sale-3": "The Official ticket release date is 10:00 AM, October 26, 2023.",
      "submit-heading": "Filling the customer detail form will:",
      "submit-text-1": "Inform you personally about the terms for ticket purchase and concert attendance.",
      "submit-text-2": "Expedite and simplify the ticket purchase process after tickets are released for sale.",

      "thank-you-sale": "The Official ticket release date is",
      "thank-you-date": "10:00 AM, October 26, 2023.",
      "thank-you-btn": "Register me",
      "thank-you-reg-message": "Регистрирай се за предварителната продажба на билети за невероятния концерт на Derullica и стани един от първите фенове с достъп до концерта на годината!",

      "login-acc": "Login with your Ticketstation account",
      "log": "Login",

      "thank-you-for-reg": "Thank you very much for filling in your details in advance!",
      "already-part": "You’ve registered an account with TicketStation.bg which is the official ticket seller for the concert of Ed Sheeran in Bulgaria.",
      "concert": "невероятния концерт на Derullica!",
      "pre-sale-starts": "The Official ticket release date is",
      "extra": "Do you plan to purchase additional tickets for your friends and family?",
      "btn-down": "Add them now in your account so you will have their details already filled in on ticket sale date:",
      "max-tickets": "I want to add friends and family (up to 5 people allowed for the tour)",
      "max-tickets-reached": "Maximum additional tickets reached!",
      "extra-tickets": "Added friends and family:",
      "friends-attention": "make sure you have their current phone and email so they can receive the most important information and conditions for attending the concert!",
      "attention": "Important:",
      "phone-attention": "Regarding every additional ticket that you plan to purchase – you will need to enter the concert all together – due to the Tour’s terms & conditions.",
      "saved": "Changes saved.",
      "follow-us": "Follow us",
      "ticket-for": "Fill in the ticket holder details – Friends & Family:",
      "logout": 'Logout',
      "description-1": "In the run up to putting a tour on sale we get asked lots of questions relating to the shows and ticket sales.",
      "description-2": "We are working hard to do everything we can to make sure that tickets only end up in the hands of genuine fans. This is why the Tour has own terms and conditions that every customer needs to comply.",
      "description-3": "It is very important to be well informed – you and every person with whom you plan to share this experience.",
      "description-4": "Make sure you read your e-mail often – we will sent important information on your e-mail you’ve just filled in.",
      "error": "Error",
      "invalid-code": 'Invalid confirmation code',

      "something-wrong-1": "Dear Fans,",
      "something-wrong-2": "Our website is currently experiencing exceptionally high traffic, which has temporarily led to some technical difficulties.",
      "something-wrong-3": "Our team is working diligently to resolve this issue and get you access to the page.",
      "something-wrong-4": "Please hang tight, as we expect to have the site back up and running smoothly very shortly. In the meantime, you can also check the",
      "something-wrong-5": "Terms and conditions of the TOUR!",
      "something-wrong-6": "We sincerely appreciate your patience.",
      "something-wrong-7": "Thank you for being a part of this amazing community of fans.",
      "something-wrong-8": "Best regards,",
      "something-wrong-9": "Ticket Station",
      "to-be": "Sofia! Ed Sheeran is bringing his Mathematics Tour 2024 for the first time in Bulgaria at Vasil Levski Stadium on August 31! This page will be live at 12pm today and will provide you with options to prepare for official ticket sales for the event.",
      "closed-text-1": "The customer form is now closed!",
      "closed-text-2": "Ticket sales start 10:00 AM on Oct 26.",
      "buy-tickets": "Buy tickets",
  }
}