import Vue from 'vue'
import App from './App.vue'
import router from './router'
import cognitoAuth from './cognito/index'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import store from './store'
import Notifications from 'vue-notification'
import { BFormDatepicker, VBModal } from 'bootstrap-vue'
import { DropdownPlugin } from 'bootstrap-vue'
import { BButton } from 'bootstrap-vue'
import { ModalPlugin } from 'bootstrap-vue'
import i18next from 'i18next';
import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import VueI18Next from '@panter/vue-i18next';
import en from "./locales/en.js";
import bg from "./locales/bg.js";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/bg.js';

/* add fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* add some free styles */
import { faPencil,faCircleXmark } from '@fortawesome/free-solid-svg-icons'
import { faTwitter,faFacebookF,faTiktok,faInstagram } from '@fortawesome/free-brands-svg-icons'

/* add each imported icon to the library */
library.add(faTwitter, faPencil,faCircleXmark,faFacebookF,faTiktok,faInstagram)

Vue.use(Notifications)
Vue.config.productionTip = false
Vue.prototype.$cognitoAuth = cognitoAuth;
Vue.directive('b-modal', VBModal)
Vue.component('b-button', BButton)
Vue.component('b-form-datepicker', BFormDatepicker);
Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('DatePicker', DatePicker);

Vue.use(DropdownPlugin)
Vue.use(ModalPlugin)
Vue.use(VueI18Next);


i18next
  .use(Backend)
  .use(LanguageDetector)
  .init({
    lng : "bg",
    resources: {
      "bg": bg,
      "en": en
    },
    debug: true, // Set to false in production
    // backend: {
    //   backends: [
    //     HttpBackend,
    //     resourcesToBackend(bundledResources)
    //     // resourcesToBackend((lng, ns) => import(`../src/locales/${lng}/${ns}.json`))
    //   ],
    //   backendOptions: [{
    //     loadPath: '../src/locales/en.json'
    //   }]
    // },
    fallbackLng: 'en', // Default language
    interpolation: {
      escapeValue: false, // React already escapes data
    },
  });
  const i18n = new VueI18Next(i18next);
  Vue.set(store.state, 'lang', i18next.language);
  
new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
