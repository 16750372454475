<template>
  <div id="app">
    <notifications group="app" />
    <router-view />
  </div>
</template>

<script>

export default {
  name: 'App',
  mounted() {
    var me = this;
    this.$cognitoAuth.isAuthenticated((err, loggedIn) => {
      if (loggedIn) {
        this.$cognitoAuth.getCurrentUser().getSession(function (err, session) {
          if (err) {
            // alert(err.message || JSON.stringify(err));
            return;
          }
          me.$store.state.auth.user = session.idToken.payload;
          me.$store.state.auth.jwtToken = session.getAccessToken().getJwtToken();
          me.$store.state.auth.accessToken = session.getIdToken().getJwtToken();

        });
      }
    });
  },
  components: {

  }
}
</script>

<style>
@import url(https://db.onlinewebfonts.com/c/1dc8ecd8056a5ea7aa7de1db42b5b639?family=Gilroy-Regular);

#app {
  font-family: Gilroy-Regular;
}

/* #app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
} */


.my-dropdown-menu {
  background-color: white !important;
  width: fit-content !important;
}

.dropdown-menu {
  min-width: 0 !important;
}

.show>.btn-secondary.dropdown-toggle {
  background-color: rgba(255, 255, 255, 0) !important;
}

.btn-secondary {
  background-color: rgba(255, 255, 255, 0) !important;
  border: none !important;
}
</style>
