import Vue from 'vue'
import Vuex from 'vuex'



Vue.use(Vuex)
const state = {
    auth: {},
   
}
export default new Vuex.Store({
  state
})